<template>
  <v-row>
    <v-col lg="12">
      <s-crud title="Parámetros" pdf :pdf="$fun.isAdmin()" excel :excel="$fun.isAdmin()" edit  add :add="$fun.isAdmin()" remove :remove="$fun.isAdmin()" restore sortable search-input :config="this.config" @save="savePrm($event)" :filter="filter">
        <template slot-scope="props">
          <v-container v-if="props.item != null">
            <v-row>
              <v-col cols="12" md="6" class="s-col-form">
                <s-text v-model="props.item.PrmName" label="Parámetro" autofocus :readonly="!$fun.isAdmin()"/>
              </v-col>
              <v-col cols="12" md="6" class="s-col-form">
                <s-text v-model="props.item.PrmDescription" label="Descripción" :readonly="!$fun.isAdmin()"/>
              </v-col>
              <v-col cols="4" class="s-col-form">
                <s-select-definition :def="1028" label="Tipo Valor" v-model="props.item.TypeValue" :readonly="!$fun.isAdmin()"/>
              </v-col>
              <v-col cols="8" class="s-col-form">
                <s-text v-model="props.item.PrmValue" label="Valor" />
              </v-col>
            </v-row>
          </v-container>
        </template>
      </s-crud>
    </v-col>
  </v-row>
</template>

<script>
import _sParameter from "@/services/General/ParameterService";
import { add } from "lodash";
import { filter } from "lodash";
export default {
  data: () => ({
    config: {
      model: {
        PrmID: "ID",
        PrmName: "string",
        PrmDescription: "string",
        TypeValue: "boleam",
        TypeValueText: "string",
        SecStatus: "status",
        TypeCampaign: "boleam",
        TypeCampaignText: "string",
      },
      service: _sParameter,
      headers: [
        {
          text: "ID",
          align: "end",
          sortable: true,
          value: "PrmID",
          width: "10%",
        },
        {
          text: "Parámetro",
          value: "PrmName",
          width: "20%",
          sortable: true,
        },
        {
          text: "Descripción",
          value: "PrmDescription",
          width: "45%",
          sortable: true,
        },
        {
          text: "Tipo",
          value: "TypeValueText",
          width: "10%",
          align: "center",
          sortable: false,
        },
        {
          text: "Valor",
          value: "PrmValue",
          width: "25%",
          sortable: false,
        },

        {
          text: "Estado",
          value: "SecStatus",
          width: "5%",
          align: "center",
          sortable: false,
        },
      ],
    },
    filter: {},
  }),

  methods: {
    validatedPrm(item) {
      let isValid = false;
      let message = "";

      isValid = item.PrmName.length > 0;
      if (!isValid) message = "Ingrese nombre de parámetro";
      else {
        isValid = item.PrmDescription.length > 0;
        if (!isValid) message = "Ingrese descripción";
        else {
          isValid = item.TypeValue > 0;
          if (!isValid) message = "Seleccione tipo de valor";
          else {
            isValid = item.PrmValue.length > 0;
            if (!isValid) message = "Ingrese valor de parámetro.";
          }
        }
      }

      if (!isValid) this.$fun.alert(message, "warning");

      return isValid;
    },
    savePrm(item) {
      if (this.validatedPrm(item)) item.save();
    },
  },
  computed: {
    
  },
  created() {
    this.filter = {
      UsrID: this.$fun.isAdmin() ? null : this.$fun.getUserID()
    };
    
  },
};
</script>
